import React, { Component } from "react"
import { graphql, Link, navigate } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";
import { getTranslations, changeToLocalLinks, createMetaDesc } from "../components/helpers/helpers";
import i18Data from '../../content/intl/labels.json';

import "../styles/page.scss"

class Page extends Component {

    componentDidMount() {
        this.windowRef = window;
        this.documentRef = document;
        this.linksUnsubs = changeToLocalLinks(this.isHtml, navigate, [ i18Data.common.url, i18Data.common.altUrl ]);
    }

    componentWillUnmount() {
        if (this.isHtml) {
            this.isHtml.removeEventListener("click", this.linksUnsubs);
        }
    }

    render() {
        const { data: { pageData, casesData }, pageContext: { lang, translateKey, prefix } } = this.props;
        const page = pageData.edges.filter(({ node }) => node.frontmatter.lang === lang)[ 0 ]?.node;
        const translations = getTranslations(pageData.edges, translateKey);
        const textDesc = createMetaDesc(page.frontmatter.summary || page.frontmatter.title);
        const i18 = i18Data[ lang ];

        const casesList = casesData.edges.filter(({ node }) => node.frontmatter.type === "0").sort(sortOrder);
        const exercisesList = casesData.edges.filter(({ node }) => node.frontmatter.type === "1").sort(sortOrder);

        return (
            <Layout mainClassName="page" data={{}} noActiveMenu={true} pageContext={this.props.pageContext} 
                translations={translations}>

                <SEO title={page.frontmatter.title} 
                    description={textDesc} lang={lang ? lang : "en" }/>
                
                <div className="page-container initial-padding">
                    <div className="page-top">
                        <ul className="breadcrumbs">
                            <li><Link to="..">{i18.labels.teachingMaterials}</Link></li>
                        </ul>
                        <h1 dangerouslySetInnerHTML={{ __html: page.frontmatter.simpleTitle }}></h1>
                    </div>

                    <div className="is-html with-headers max-width" 
                        ref={(t) => { this.isHtml = t }}
                        dangerouslySetInnerHTML={{ __html: page.html }}></div>

                    <div className="is-html with-headers teaching-materials-themes">
                        { casesList.length > 0 &&
                        <>
                        <h2>{i18.labels.cases}</h2>
                        <ul>
                            {casesList.map(({node}, idx) => 
                                <li key={`ex-${idx}`}>
                                    <Link to={(prefix ? prefix + "/" : "/") + node.frontmatter.slug}>{node.frontmatter.title}</Link>
                                    &nbsp; (ref. {node.frontmatter.originalRef})
                                </li>
                            )}
                        </ul>
                        </>
                        }
                        
                        { exercisesList.length > 0 &&
                        <>
                        <h2>{i18.labels.exercises}</h2>
                        <ul>
                            {exercisesList.map(({node}, idx) => 
                                <li key={`ex-${idx}`}>
                                    <Link to={(prefix ? prefix + "/" : "/") + node.frontmatter.slug}>{node.frontmatter.title}</Link>
                                    &nbsp; (ref. {node.frontmatter.originalRef})
                                </li>
                            )}
                        </ul>
                        </>
                        }

                        <br />
                        <Link className="rounded-padded-link fontSize85" to="..">&larr; {i18.labels.back}</Link>
                    </div>

                </div>

            </Layout>
        )
    }
}

export default Page


export const query = graphql`
    query MatDocThemeQuery($translateKey: String!, $originalId: String!, $lang: String!) {
        pageData: allMarkdownRemark(filter: { frontmatter: { template:{ eq: "matDoc" }, translateKey: { eq: $translateKey }} }) {
            edges {
                node {
                    frontmatter {
                        lang
                        template
                        slug
                        summary
                        title
                        simpleTitle
                        translateKey
                        originalId
                    }
                    id
                    html

                }
            }
        }
        casesData: allMarkdownRemark(
            filter: { 
                frontmatter: { 
                    template:{ eq: "matDocItem" }, 
                    subjectId: { eq: $originalId }
                    lang: { eq: $lang }
                }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        lang
                        template
                        slug
                        summary
                        title
                        translateKey
                        subjectId
                        originalRef
                        order
                        type
                    }
                }
            }
        }

    }
`

function sortOrder(a, b) {
    let aD = a.node.frontmatter.order,
        bD = b.node.frontmatter.order,
        orderDate = aD === bD ? 0 : (aD > bD ? 1 : -1);

    return orderDate;
}